a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

.MuiAutocomplete-tag {
  background-color: #d6d3cb;
}

.MuiChip-deleteIcon {
  color: white;
}

ul {
  justify-content: center;
}

.MuiChip-label {
  color: black;
}
