section {
  display: flex;
}

.homePage {
  min-height: 85vh;
}

.personalInfo {
  margin-left: 10vw;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 768px) {
    width: 70%;
  }
}

.iconContainer {
  display: flex;
  gap: 15px;
}

.coderImgContainer {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
