.cardContainer {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 20px;
}

.card {
  background-color: #f9fafb;
  padding: 5px;
  display: flex;
  width: 10vw;
  max-width: 200px;
  min-width: 100px;
  border-radius: 10px;
  justify-content: space-around;
  align-items: center;
}

.cardName {
  color: black;
  padding: 15px 0px;
}
