.portfolioCardContainer {
  padding: 20px 10vw;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 20px 10px;
    align-items: center;
  }
}

.MuiCard-root {
  min-width: 35vw;
  @media screen and (max-width: 768px) {
    width: 90vw;
  }
}
